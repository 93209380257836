import React from "react";

const AboutUs = () => {
  return (
    <div>
      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-7">
              <div className="section-title position-relative pb-3 mb-5">
                <h2 className="fw-bold text-primary text-uppercase mb-4">
                  About Us
                </h2>
                <h1 className="mb-0">
                  The Best IT Solution With 08 Years of Experience
                </h1>
              </div>
              <p className="mb-4">
                Welcome to Shrewdmind Private Ltd. Your trusted online
                destination for all your grocery, food, and everyday essentials
                needs. At Shrewdmind, we believe in making shopping for everyday
                necessities simple, affordable, and efficient. As a leading
                eCommerce company, we offer a wide range of high-quality
                products, from fresh groceries to packaged foods, household
                supplies, personal care items, and more. With a focus on
                customer satisfaction and convenience, we are committed to
                delivering a seamless shopping experience to every household.
                Our Vision To revolutionize the way people shop for groceries
                and daily essentials by providing easy access to quality
                products at competitive prices. We aim to become the go-to
                platform for individuals and families looking to shop smart and
                save time. Our Mission Quality First: We work closely with
                trusted suppliers to bring you only the finest products, from
                fresh produce to premium pantry staples. Affordability: We
                believe that everyone should have access to high-quality goods
                without breaking the bank. We offer competitive pricing,
                discounts, and promotions to make shopping affordable.
                Convenience: With just a few clicks, shop from the comfort of
                your home and have your products delivered right to your
                doorstep. We prioritize quick and reliable delivery to ensure
                that you always have what you need when you need it. What We
                Offer: Grocery & Fresh Produce: A wide selection of fruits,
                vegetables, dairy, meat, and other essential food items.
                Packaged & Processed Foods: Snacks, beverages, spices, sauces,
                and ready-to-eat meals. Household & Personal Care: Cleaning
                supplies, toiletries, health & wellness products, and more. And
                Much More: Explore our variety of products across different
                categories to fulfill all your home and lifestyle needs. Why
                Choose Us? Quality Assurance: We carefully curate every product
                in our catalog to meet the highest standards of quality.
                Customer-Centric Approach: Our team is dedicated to ensuring
                your satisfaction, offering prompt customer support and
                hassle-free returns. Secure Shopping Experience: We take your
                privacy and security seriously, with a robust and secure
                platform for smooth transactions. Timely Delivery: We strive to
                offer fast and reliable delivery options to get your essentials
                to you as quickly as possible. Join the Shrewdmind Family
                Whether you're stocking up for the week or finding that one
                ingredient you've been searching for, Shrewdmind Private Ltd. is
                here to make your life easier. We're more than just an eCommerce
                platform – we’re your partner in convenience and quality. Thank
                you for choosing us as your preferred shopping destination. We
                look forward to serving you!
              </p>
              <div className="row g-0 mb-3">
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                  <h5 className="mb-3 row">
                    <i class="fa fa-envelope" aria-hidden="true"></i>
                    <p style={{ marginLeft: "10px" }}>support@shrewdmind.in</p>
                  </h5>
                </div>
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                  <h5 className="mb-3 row">
                    <i class="fa fa-address-book" aria-hidden="true"></i>
                    <p style={{ marginLeft: "10px" }}>
                      +91- 8076877163 , +91- 8059942293
                    </p>
                  </h5>
                </div>
                <div className="col-sm-9 wow zoomIn" data-wow-delay="0.2s">
                  <h5 className="mb-3 row">
                    <i class="fa fa-address-book" aria-hidden="true"></i>
                    <p style={{ marginLeft: "10px" }}>
                      D-298 Street No 11 Harsh Vihar, 110093 New Delhi
                    </p>
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-5" style={{ minHeight: "500px" }}>
              <div className="position-relative h-25">
                <img
                  className="position-absolute w-100 h-100 rounded wow zoomIn"
                  data-wow-delay="0.9s"
                  src="../Assets/images/carousel-1.jpg"
                  style={{ objectFit: "cover" }}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
