import React, { useEffect, useState } from "react";
import "./shop.css";
import axios from "axios";
import { baseUrl } from "../env";
import { useNavigate } from "react-router-dom";

// Recent shop card component
const ShopCard = ({ shop }) => {
  const navigate = useNavigate();
  const calcDistance = (dist) => {
    if (!dist) return "NA";
    else if (dist < 1000) return Math.floor(dist) + " meter";
    else return Math.floor(dist / 1000) + " Km";
  };

  return (
    <div className="shop-card" onClick={() => navigate("/" + shop?._id)}>
      <img
        src={baseUrl + "/file/load/" + shop?.logo}
        alt=""
      />
      <h3 className="m-0 p-0 mt-2">{shop?.organization}</h3>
      <p>Distance - {calcDistance(shop?.distance)}</p>
      <p>{shop?.description}</p>
    </div>
  );
};

// Main component
const Shop = () => {
  const [error, setError] = useState(null); // To store error message
  const [loading, setLoading] = useState(true); // To show loading state
  const [shops, setShop] = useState([]); // To show loading state
  const [recentShops, setRecentShop] = useState([]);

  useEffect(() => {
    // Function to get the current location
    let vendorList = localStorage.getItem("vendorList")
      ? JSON.parse(localStorage.getItem("vendorList"))
      : [];
    setRecentShop(vendorList);
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            sendLocationToBackend(latitude, longitude);
            setLoading(false);
            console.log(latitude, longitude);
          },
          (err) => {
            setError("Error getting location: " + err.message);
            setLoading(false);
          }
        );
      } else {
        setError("Geolocation is not supported by this browser.");
        setLoading(false);
      }
    };

    getLocation();
  }, []);

  const sendLocationToBackend = async (lat, long) => {
    const data = {
      lat: Number(lat),
      long: Number(long),
    };

    let config = {
      method: "POST",
      url: baseUrl + "catalouge/nearme",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      setShop(response.data?.data || []);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
   <div className="container">
     <div className="shop-visits">
      <h2>Recent Shop Visits</h2>

      <div className="recent-shops">
        {recentShops.map((shop) => (
          <div className="shop-card-container" key={shop.id}>
            <ShopCard shop={shop} />
          </div>
        ))}
      </div>

      <h2>{shops.length} Popular shops near you</h2>
      <div className="more-shops">
        {shops?.length &&
          shops?.map((shop) => (
            <div className="shop-card-container" key={shop?.id}>
              <ShopCard shop={shop} />
            </div>
          ))}
      </div>
    </div>
   </div>
  );
};

export default Shop;
