import axios from "axios";
import "./App.css";
import AllRoute from "./Route/AllRoute";
import { useState } from "react";


function App() {
  const [loader,setLoader]=useState(true)

  axios.interceptors.request.use((request) => {
    setLoader(true)
    if (request) {
      request.headers.token=localStorage.getItem('token')
    }
    return request;
}, (error) => {
  setLoader(false)
  console.log('axios err=====================',error)
     
    return Promise.reject(error.message);
});

axios.interceptors.response.use(resp=>{
  setLoader(false)
  return resp
})
  return (
    <div className="d-flex flex-column min-vh-100">
     
      <AllRoute />
      {loader && <div className="loader-container">
      <div className="loader" disabled/>
      </div>}
    </div>
  );
}

export default App;
