import React, { useEffect, useState } from "react";
import "./Cart.css";
import { Link, useNavigate } from "react-router-dom";
import LoginModal from "./LoginModal";
import { baseUrl } from "../env";
import { getCart, updateCart } from "../Api/AllApi";
import { getGlobalData, insertGlobalData } from "../Api/saveData";
const fontColor = { "#fff": "#000", "#ffffff": "#000", white: "#000" };
let vendorId = localStorage.getItem("vendorId");

function Cart() {
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(true);
  };
  const navigate = useNavigate();

  const [value, setValue] = useState([]);
  const [totalObj, setTotalObj] = useState({
    total: 0,
    ftotal: 0,
    totalQty:0,
    deliveryCharges:0
  });

  useEffect(() => {
    calculation();
  }, []);

  const calculation = () => {
    getCart(vendorId).then((resp) => {
      totalObj.total = 0;
      totalObj.ftotal = 0;
      totalObj.totalQty = 0;
      totalObj.deliveryCharges = getGlobalData('vendorInfo')?.deliveryCharges||0;
     
      let cartList = resp.data.data;
      cartList.forEach((item) => {
        totalObj.totalQty+=item.qty
        item.fprice = item.productId.price;
        item.productId.offerPrice?.forEach((price) => {
          if (price.qty <= item.qty) {
            item.fprice =
              item.productId.price - (item.productId.price * price.price) / 100;
          }
        });
        totalObj.total += item.qty * item.productId.price;
        totalObj.ftotal += item.qty * item.fprice;
        
      });
      if(totalObj.total>=getGlobalData('vendorInfo')?.minOrder){
        totalObj.deliveryCharges=0
      }
      setTotalObj({ ...totalObj });
      setValue([...cartList]);
      createUpdateEvent()
    });
  };
  const addCart = (obj, i) => {
    if (value[i].qty) value[i].qty += 1;
    else value[i].qty = 1;
    updateCart({_id:obj._id,qty:value[i].qty}).then(resp=>{
      calculation()
    }).catch(err=>{
      console.log('update error =====================',err);
    })
    
  };

  const createUpdateEvent = () => {
    const event = new Event("cart");
    event.amount = totalObj.total;
    event.qty = totalObj.totalQty;
    event.famount=totalObj.ftotal
    document.dispatchEvent(event);
  };

  const deleteToCart = (obj, i) => {
    if (value[i].qty) value[i].qty -= 1;
    else value[i].qty = 1;
    updateCart({_id:obj._id,qty:value[i].qty}).then(resp=>{
     calculation()
    }).catch(err=>{
      console.log('update error =====================',err);
    })
  };

  const AddressPage = () => {
    let otptoken = localStorage.getItem("token");
    if (!otptoken) {
      handleShow();
    } else {
      navigate("/getaddress");
      insertGlobalData('cart',{totalObj,cartList:value})
    }
  };
  return (
    <>
      {value?.length && (
        <section className="shoping-cart spad">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="shoping__cart__table">
                  <table>
                    <thead>
                      <tr>
                        <th className="shoping__product">Products</th>
                        <th>Price</th>
                        <th>Offer Price</th>
                        <th>Quantity</th>
                        <th>Total</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {value?.length > 0 &&
                        value?.map((item, i) => (
                          <tr key={"cart" + i}>
                            <td className="shoping__cart__item">
                              <img
                                className="img_size"
                                src={
                                  baseUrl +
                                  "/file/load/" +
                                  item?.productId?.images
                                }
                                alt="pickupbill"
                              />
                              <h5>{item?.productId?.name}</h5>
                            </td>
                            <td className="shoping__cart__price">
                              <del style={{ color: "red" }}>
                                {" "}
                                ₹{item?.productId?.price}
                              </del>
                            </td>
                            <td className="shoping__cart__price">
                              ₹{item?.fprice}
                            </td>
                            <td className="shoping__cart__quantity">
                              <div className="quantity">
                                <div className="pro-qty">
                                  <i
                                    className="btn btn-sm"
                                    onClick={() => deleteToCart(item, i)}
                                  >
                                    -
                                  </i>
                                  {/* <input value={item?.qty}/> */}
                                  {item?.qty}
                                  <i
                                    className="btn btn-sm"
                                    onClick={() => addCart(item, i)}
                                  >
                                    {" "}
                                    +
                                  </i>
                                </div>
                              </div>
                            </td>
                            <td className="shoping__cart__total">
                              ₹{item?.qty * item?.fprice}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="shoping__cart__btns">
                  <Link to="/" className="primary-btn">
                    CONTINUE SHOPPING
                  </Link>
                  {/* <Link to="/" className="primary-btn cart-btn cart-btn-right">
                    <span className="icon_loading"></span>
                    Upadate Cart
                  </Link> */}
                </div>
              </div>
              <div className="col-lg-6"></div>
              <div className="col-lg-6">
                <div className="shoping__checkout">
                  <h5>Cart Total</h5>
                  <ul>
                    <li>
                      Subtotal <span>₹{totalObj.total} </span>
                    </li>
                    <li>
                      Delivery Charges
                      <span>₹{totalObj.deliveryCharges} </span>
                    </li>
                    <li>
                      Discount{" "}
                      <span>-₹{totalObj.total - totalObj.ftotal} </span>
                    </li>
                    <li>
                      Total <span>₹{totalObj.ftotal+totalObj.deliveryCharges}</span>
                    </li>
                  </ul>
                  <LoginModal setShow={setShow} show={show} />
                  <i
                    className="primary-btn"
                    style={{ cursor: "pointer" }}
                    onClick={AddressPage}
                  >
                    PROCEED TO CHECKOUT
                  </i>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {!value?.length && (
        <div class="card-body cart">
          <div class="col-sm-12 empty-cart-cls text-center">
            <img
              alt=""
              src="https://i.imgur.com/dCdflKN.png"
              width="130"
              height="130"
              class="img-fluid mb-4 mr-3"
            />
            <h3>
              <strong>Your Cart is Empty</strong>
            </h3>
            <h4>Add something to make me happy :)</h4>
            <Link
              to="/"
              className="btn btn-primary cart-btn-transform m-3"
              data-abc="true"
            >
              continue shopping
            </Link>
          </div>
        </div>
      )}
    </>
  );
}

export default Cart;
