const data={};

export const insertGlobalData=(key,val)=>{
    data[key]=val
};

export const getGlobalData=(key)=>{
    return data[key]?{...data[key]}:null
};

