import React from "react";

const PrivacyPolicy = () => {
  return (
    <div>
      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-7">
              <div className="section-title position-relative pb-3 mb-5">
                <h2 className="fw-bold text-primary text-uppercase mb-4">
                  Privacy Policy
                </h2>
                {/* <h1 className="mb-0">
                  The Best IT Solution With 08 Years of Experience
                </h1> */}
              </div>
              <p className="mb-4">
                Privacy Policy of Shrewdmind Private Ltd. Effective Date:
                02-January-2024 At Shrewdmind Private Ltd. ("we", "our", "us"),
                your privacy is of paramount importance to us. This Privacy
                Policy explains how we collect, use, disclose, and protect your
                personal information when you visit and interact with our
                website and eCommerce platform. We are committed to safeguarding
                your privacy and ensuring that your personal data is handled
                securely and in accordance with applicable laws. By using our
                website and services, you agree to the terms outlined in this
                Privacy Policy. Please read it carefully to understand our views
                and practices regarding your personal data and how we will treat
                it.
                <ul>
                  <li>
                    Information We Collect We collect various types of
                    information from you when you interact with our website and
                    services. This includes: a. Personal Information When you
                    register an account, make a purchase, subscribe to our
                    newsletter, or contact customer service, we may collect
                    personal information such as: Name Email address Phone
                    number Delivery address Payment details (credit/debit card
                    information, billing address) Date of birth (if required for
                    age verification or specific services) b. Non-Personal
                    Information We may also collect non-personal information,
                    including but not limited to: Browser type and version IP
                    address Device type and operating system Pages visited on
                    our site Time spent on our website Referring websites and
                    search terms used This information helps us understand how
                    visitors interact with our site and allows us to enhance
                    your user experience. c. Cookies and Tracking Technologies
                    We use cookies, web beacons, and similar tracking
                    technologies to enhance your experience on our site. Cookies
                    are small text files stored on your device that help us
                    recognize you on return visits and tailor your experience.
                    You can choose to disable cookies through your browser
                    settings, but please note that some features of our site may
                    not function properly without them.
                  </li>
                  <li>
                    How We Use Your Information We use the information we
                    collect in the following ways: To process orders and deliver
                    products: We use your contact and payment information to
                    fulfill your orders and deliver products to the address you
                    provide. To improve our services: We analyze user data to
                    enhance website functionality, personalize content, and
                    improve customer experience. To communicate with you: We may
                    send you order confirmations, shipping updates, marketing
                    promotions, and customer service responses. For fraud
                    prevention and security: We use your information to protect
                    our website and services from unauthorized access, fraud,
                    and abuse. For marketing and promotional purposes: With your
                    consent, we may use your email address to send you
                    newsletters, promotional offers, or updates related to our
                    services. You can opt out at any time by unsubscribing.
                  </li>
                  <li>
                    How We Share Your Information We respect your privacy and
                    will not sell or rent your personal information to third
                    parties. However, we may share your information in the
                    following circumstances: Service Providers: We may share
                    your information with trusted third-party service providers
                    who assist us with payment processing, shipping, marketing,
                    and website analytics. These providers are required to
                    maintain the confidentiality of your data and use it only
                    for the purposes for which it was shared. Legal Compliance:
                    We may disclose your personal information if required by
                    law, court order, or to comply with legal processes or
                    protect our rights, property, and safety. Business
                    Transfers: In the event of a merger, acquisition, or sale of
                    our business assets, your information may be transferred as
                    part of that transaction. We will notify you of such changes
                    as required by law.
                  </li>
                  <li>
                    Data Security We take the security of your personal
                    information seriously and employ a variety of security
                    measures, including encryption, firewalls, and secure
                    servers, to protect your data. However, no method of
                    transmission over the Internet or electronic storage is
                    completely secure, so we cannot guarantee absolute security.
                    We encourage you to protect your login credentials and not
                    share your account details with anyone. If you suspect
                    unauthorized access to your account, please notify us
                    immediately.
                  </li>
                  <li>
                    Your Rights and Choices You have the right to manage your
                    personal information and make certain choices about how we
                    collect and use your data: Access and Correction: You can
                    request access to the personal information we hold about you
                    and request corrections or updates if necessary. Opt-Out of
                    Marketing Communications: You can opt out of receiving
                    promotional emails from us by following the "unsubscribe"
                    link in our emails or by contacting us directly. Cookies
                    Preferences: You can control and manage cookies through your
                    browser settings. Please note that disabling cookies may
                    affect the functionality of some features on our website.
                    Delete Account: You may request to delete your account and
                    associated data by contacting our customer support team.
                    Some data may be retained as required by law or for
                    legitimate business purposes.
                  </li>
                  <li>
                    Third-Party Links Our website may contain links to
                    third-party websites, products, or services that are not
                    operated by us. These third-party sites have their own
                    privacy policies, and we encourage you to review them before
                    providing any personal information. We are not responsible
                    for the content or practices of these external sites.
                  </li>
                  <li>
                    Children's Privacy Our services are not intended for
                    children under the age of 13, and we do not knowingly
                    collect personal information from children. If we become
                    aware that we have collected personal information from a
                    child under 13, we will take steps to delete that
                    information as soon as possible. If you believe we may have
                    collected such information, please contact us.
                  </li>
                  <li>
                    {" "}
                    Changes to This Privacy Policy We may update this Privacy
                    Policy from time to time. When we do, we will post the
                    revised version on our website with the updated "Effective
                    Date" at the top. We encourage you to review this policy
                    periodically to stay informed about how we are protecting
                    your information.
                  </li>

                  <li>
                    Contact Us If you have any questions or concerns about this
                    Privacy Policy or how we handle your personal information,
                    please feel free to contact us: Shrewdmind Private Ltd.
                    Thank you for choosing Shrewdmind Private Ltd.! We look
                    forward to providing you with the best online shopping
                    experience.
                  </li>
                </ul>
              </p>
              <div className="row g-0 mb-3">
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                  <h5 className="mb-3 row">
                    <i class="fa fa-envelope" aria-hidden="true"></i>
                    <p style={{ marginLeft: "10px" }}>support@shrewdmind.in</p>
                  </h5>
                </div>
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                  <h5 className="mb-3 row">
                    <i class="fa fa-address-book" aria-hidden="true"></i>
                    <p style={{ marginLeft: "10px" }}>
                      +91- 8076877163 , +91- 8059942293
                    </p>
                  </h5>
                </div>
                <div className="col-sm-9 wow zoomIn" data-wow-delay="0.2s">
                  <h5 className="mb-3 row">
                    <i class="fa fa-address-book" aria-hidden="true"></i>
                    <p style={{ marginLeft: "10px" }}>
                      D-298 Street No 11 Harsh Vihar, 110093 New Delhi
                    </p>
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-5" style={{ minHeight: "500px" }}>
              <div className="position-relative h-25">
                <img
                  className="position-absolute w-100 h-100 rounded wow zoomIn"
                  data-wow-delay="0.9s"
                  src="../Assets/images/about.jpg"
                  style={{ objectFit: "cover" }}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
