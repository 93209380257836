import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import "./Header.css";
import LoginModal from "../pages/LoginModal";
import { getCart, venderDetail } from "../Api/AllApi";
import { baseUrl } from "../env";
import { insertGlobalData } from "../Api/saveData";

function Header() {
  const navigate = useNavigate();
  const location = useLocation();

  const [count, setCount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [show, setShow] = useState(false);
  const [vendorData, setVendorData] = useState("");
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [Token, setToken] = useState("");
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      let vendorId = localStorage.getItem('vendorId');
      if(vendorId){
      venderDetail(vendorId)
        .then((res) => {
          if (res.data.data) {
            setVendorData({ ...res.data.data });
            setIsOpen(res.data.data.isShop);
            insertGlobalData('vendorInfo',res.data.data)
            let vendorList = localStorage.getItem("vendorList")
              ? JSON.parse(localStorage.getItem("vendorList"))
              : [];
            let vendorObj = vendorList.find((item) => item._id === vendorId);
            if (!vendorObj) {
              vendorList.unshift({ ...res.data.data, _id: vendorId });
              if(vendorList.length>3)
                vendorList.pop()
              localStorage.setItem("vendorList", JSON.stringify(vendorList));
            }
          }
        }).catch((err) => {
          setIsOpen(false);
          console.log(err, "error");
        });
        getCartDetails()
      }
    }, 100);

  }, [location]);


  // useEffect()
  const handleShow = () => {
    setShow(!show);
  };

  const getCartDetails = () => {
    let token = localStorage.getItem("token");
    let vendorId = localStorage.getItem('vendorId');
    if (token && vendorId) {
      getCart(vendorId).then((resp) => {
        const totalObj = { total: 0, ftotal: 0, totalQty: 0 }
        let cartList = resp.data.data;
        cartList.forEach((item) => {
          totalObj.totalQty += item.qty
          item.fprice = item.productId.price;
          item.productId.offerPrice?.forEach((price) => {
            if (price.qty <= item.qty) {
              item.fprice =
                item.productId.price - (item.productId.price * price.price) / 100;
            }
          });
          totalObj.total += item.qty * item.productId.price;
          totalObj.ftotal += item.qty * item.fprice;

        });
        setTotalAmount(totalObj.ftotal)
        setCount(totalObj.totalQty)
        insertGlobalData('cart', { cartList, totalObj })
      });
    }
  };

  // const getItemCount = () => {
  //   const vendorId = localStorage.getItem("vendorId");
  //   const str = localStorage.getItem("cart-" + vendorId);
  //   let cartlist = str ? JSON.parse(str) : [];
  //   let total = 0;
  //   let tamount = 0;
  //   let famount = 0;
  //   cartlist.map((item) => {
  //     if (item.qty) {
  //       total += Number(item.qty);
  //       item.fprice = item.price;
  //       item.offerPrice?.forEach((price) => {
  //         if (price.qty <= item.qty) {
  //           item.fprice = item.price - (item.price * price.price) / 100;
  //         }
  //       });
  //       tamount += item.qty * item.price;
  //       famount += item.qty * item.fprice;
  //     }
  //   });
  //   setTotalAmount(famount);
  //   setCount(total);
  // };

  const getEvent = (a) => {
    document.addEventListener("cart", (e) => {
      console.log('-----------------------', e);
      if (e.isAddtoCart) {
        getCartDetails()
      } else {
        setCount(e.qty)
        setTotalAmount(e.amount)
      }
      // getItemCount();
    });

    document.addEventListener("login", () => {
      let t = localStorage.getItem("token");
      setToken(t);
    });
  };
  useEffect(() => {
    setToken(localStorage.getItem("token"));
    getEvent();
  }, []);

  const logOut = () => {
    localStorage.removeItem("token");
    setToken("");
    navigate("/");
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const loginCheck = () => {
    if (!localStorage.getItem('token')) {
      setShow(true)
    } else {
      navigate('/cart')
    }
  }
  return (
    <div className="header-fix">
      <div
        className={`humberger__menu__overlay ${mobileMenuOpen === true ? "active" : ""
          }`}
      ></div>
      <div
        className={`humberger__menu__wrapper ${mobileMenuOpen === true ? "show__humberger__menu__wrapper" : ""
          }`}
      >
        <span onClick={toggleMobileMenu} style={{ marginLeft: "230px" }}>
          X
        </span>
        <div className="humberger__menu__logo">
          <a href="#">
            <img src="img/cart-2.jpg" alt="" />
          </a>
        </div>

        <div className="humberger__menu__widget">
          <div className="header__top__right__auth">
            <i className="fa fa-user"> </i>{" "}
            {Token ? (
              <span onClick={logOut} className="loginbtn">
                Logout
              </span>
            ) : (
              <span onClick={handleShow} className="loginbtn">
                Login
              </span>
            )}
          </div>
        </div>

        <div id="mobile-menu-wrap"></div>
        <div className="header__top__right__social">
          <ul style={{ listStyle: "none" }}>
            <li className="active">
              <Link to="/" onClick={toggleMobileMenu}>
                Shop
              </Link>
            </li>
            {Token && (
              <li>
                <Link to="/orderlist" onClick={toggleMobileMenu}>
                  Orders
                </Link>
              </li>
            )}
            {/* <li><Link to="/cart" onClick={toggleMobileMenu}>Shoping Cart</Link></li>
                    <li><Link to="/getaddress" onClick={toggleMobileMenu}>Check Out</Link></li> */}
            <li>
              <Link to="/contactus" onClick={toggleMobileMenu}>
                Contact
              </Link>
            </li>
          </ul>
        </div>
        <div className="humberger__menu__contact">
          <ul>
            <li>
              <i className="fa fa-envelope"></i> support@shrewdmind.in
            </li>
            {/* <li>Free Shipping for all Order of ₹1000</li> */}
          </ul>
        </div>
      </div>

      <header>
        <div className="header__top" >
          <div className="container-lg">
            <div className="row">
              <div className="col-lg-6">
                <div className="header__top__left">
                  <ul>
                    <li>
                      <i className="fa fa-envelope"></i>support@shrewdmind.in
                    </li>
                    <Link to="/shopList">
                      <i className="fa fa-street-view"></i>Store near me
                    </Link>

                    {/* <li>Free Shipping for all Order of ₹1000</li> */}
                  </ul>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="header__top__right">
                  <div className="header__top__right__social">
                    <button className={`toggle-btn ${isOpen ? "on" : "off"}`}>
                      Shop is {isOpen ? "Open" : "Closed"}
                    </button>
                    {/* <p>The toggle is {isOpen ? "On" : "Off"}</p> */}
                    {/* <Link to="https://www.facebook.com/">
                      <i className="fa fa-facebook"></i>
                    </Link> */}
                    {/* <Link to="https://twitter.com">
                      <i className="fa fa-twitter"></i>
                    </Link> */}
                    {/* <Link to="https://www.linkedin.com">
                      <i className="fa fa-linkedin"></i>
                    </Link> */}
                  </div>
                  <div className="header__top__right__auth">
                    <i className="fa fa-user"> </i>{" "}
                    {!Token ? (
                      <span onClick={handleShow} className="loginbtn">
                        Login
                      </span>
                    ) : (
                      <span onClick={logOut} className="loginbtn">
                        Logout
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container vendor_detail ">
          <div className="row">
            <div className="col-lg-3">
              <Link to="/">
                {vendorData.logo ? <img
                  src={baseUrl + "/file/load/" + vendorData?.logo}
                  alt="na"
                  className="vendor__logo"
                /> : <span className="vendor__logo_blank"></span>}
                <span className="vendor_name"> {vendorData?.organization}</span>
              </Link>
            </div>
            <div className="col-lg-6">
              <nav className="header__menu">
                <ul>
                  {/* <li>
                    <Link to="/shoplist">Shop</Link>
                  </li> */}
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  {Token && (
                    <li>
                      <Link to="/orderlist">Orders</Link>
                    </li>
                  )}
                  {/* <li><Link to="/cart">Shoping Cart</Link></li>
                                    <li><Link to="/getaddress">Check Out</Link></li> */}
                  <li>
                    <Link to="/contactus">Contact</Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="col-lg-3">
              <div className="header__cart">
                <ul>
                  <li>
                    <a href="#">
                      <i className="fa fa-heart"></i> <span>1</span>
                    </a>
                  </li>
                  <li>
                    <div onClick={loginCheck}>
                      <i className="fa fa-shopping-bag"></i>{" "}
                      {count ? <span>{count}</span> : ""}
                    </div>
                  </li>
                </ul>
                <div className="header__cart__price">
                  {" "}
                  <span>₹{totalAmount}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="humberger__open" onClick={toggleMobileMenu}>
            <i className="fa fa-bars"></i>
          </div>
        </div>
      </header>
      <LoginModal setShow={setShow} show={show} />
    </div>
  );
}

export default Header;
