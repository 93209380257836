import React from "react";

const OurService = () => {
  return (
    <div>
      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-7">
              <div className="section-title position-relative pb-3 mb-5">
                <h2 className="fw-bold text-primary text-uppercase mb-4">
                  Our Service
                </h2>
                {/* <h1 className="mb-0">
                  The Best IT Solution With 08 Years of Experience
                </h1> */}
              </div>
              <p className="mb-4">
                At Shrewdmind Private Ltd., we are committed to providing a
                seamless and enjoyable shopping experience for our customers. As
                a leading eCommerce platform, we offer a wide range of services
                to meet your grocery, food, and everyday essentials needs.
                Whether you're looking to stock up your pantry, find fresh
                produce, or grab household supplies, we have you covered. Here's
                a closer look at the services we offer:
                <ul>
                  <li>
                    Online Grocery Shopping Enjoy the convenience of shopping
                    for your groceries from the comfort of your home. Our
                    platform offers a vast selection of fresh produce, dairy,
                    meat, pantry staples, and specialty items. From organic
                    products to everyday essentials, you can find everything you
                    need with just a few clicks. Wide Selection: Fruits,
                    vegetables, dairy, grains, meats, spices, and more.
                    Freshness Guaranteed: We source high-quality products and
                    ensure they are delivered fresh. Easy Shopping Experience:
                    Browse by category or use the search function to quickly
                    find your items.
                  </li>
                  <li>
                    Fresh Food Delivery Skip the hassle of going to the store –
                    we deliver fresh and frozen food directly to your doorstep.
                    Whether you're looking for ready-to-eat meals, snacks, or
                    frozen treats, we ensure that your food arrives at the
                    perfect temperature and in top condition. Ready-to-Eat
                    Meals: Quick, delicious, and nutritious meals for busy
                    families and individuals. Fresh Ingredients: Get everything
                    from fresh vegetables to specialty food items delivered in a
                    timely manner. Customized Delivery: Choose from same-day or
                    scheduled delivery options that work best for you.
                  </li>
                  <li>
                    3. Personalized Shopping Experience We understand that every
                    customer is unique. Our platform allows you to create a
                    personalized shopping experience by saving your favorite
                    products, setting up recurring orders, and receiving
                    tailored recommendations based on your preferences and
                    previous purchases. Saved Preferences: Store frequently
                    purchased items for quicker checkouts. Product
                    Recommendations: Get personalized suggestions based on your
                    buying habits. Subscription Options: Set up recurring orders
                    for your regular purchases and never run out of essentials
                    again.
                  </li>
                  <li>
                    4. Special Offers & Discounts We believe in making quality
                    products affordable. Take advantage of our regular
                    promotions, discounts, and special offers to save more on
                    your grocery and food orders. From bulk discounts to
                    exclusive deals, we ensure you get the best value for your
                    money. Weekly Deals: Check out our weekly discounts on
                    popular items. Seasonal Offers: Enjoy discounts on seasonal
                    products and festive specials. Loyalty Rewards: Earn rewards
                    points with every purchase and redeem them for discounts on
                    future orders.
                  </li>
                  <li>
                    Shipping and Delivery We offer shipping to various locations
                    as specified on our website. Delivery times are estimates
                    and may vary depending on the location, product
                    availability, and delivery method selected. Shipping
                    Charges: Shipping costs are calculated based on your
                    delivery address and the size of your order. Additional fees
                    may apply for expedited shipping or remote locations.
                    Delivery Time: While we strive to ensure timely delivery, we
                    are not responsible for delays caused by unforeseen
                    circumstances such as weather, strikes, or courier delays.
                    Shipping Risk: Once your order has been shipped, the risk of
                    loss or damage to the products passes to you.
                  </li>
                  <li>
                    Fast & Reliable Delivery We know how important it is to
                    receive your groceries and essentials on time. Our efficient
                    logistics network ensures that your orders are processed
                    quickly and delivered to your door as soon as possible. We
                    offer multiple delivery options to suit your needs. Flexible
                    Delivery Slots: Choose from same-day, next-day, or scheduled
                    delivery options. Real-Time Tracking: Track your order in
                    real-time, so you know exactly when to expect your delivery.
                    Safe Packaging: Our team ensures that all products are
                    carefully packed, especially perishable items, so they
                    arrive in perfect condition.
                  </li>
                  <li>
                    Customer Support At Shrewdmind, we prioritize customer
                    satisfaction. Our dedicated customer service team is here to
                    assist you with any questions, concerns, or issues you may
                    have. Whether you need help with an order, a product
                    inquiry, or any other assistance, we are here to ensure a
                    smooth and hassle-free experience. 24/7 Support: Our
                    customer support is available around the clock. Live Chat:
                    Instant assistance through live chat on our website. Help
                    Center: Access our comprehensive help center for FAQs, order
                    tracking, and product details.
                  </li>
                  <li>
                    Gift Cards & Gifting Solutions Looking for the perfect gift?
                    Choose from a variety of Shrewdmind Gift Cards that can be
                    used for any of our products. Whether it's a birthday,
                    anniversary, or any special occasion, our gift cards offer a
                    convenient and thoughtful gifting solution. Flexible
                    Denominations: Choose the value that suits your budget. Easy
                    to Redeem: Use gift cards online while shopping for
                    groceries or gifts. Perfect for Every Occasion: Ideal for
                    friends, family, or colleagues.
                  </li>
                  <li>
                    Corporate and Bulk Orders We also offer services tailored
                    for businesses, offices, and bulk buyers. Whether you're
                    ordering groceries for your office pantry or catering to a
                    large event, we can provide personalized solutions to meet
                    your needs. Bulk Discounts: Enjoy special pricing when
                    ordering in large quantities. Corporate Accounts: Set up an
                    account for seamless, recurring orders for your office or
                    business. Event Catering: We can help supply groceries and
                    food items for corporate events, conferences, and
                    gatherings.
                  </li>
                  <li>
                    Limitation of Liability To the fullest extent permitted by
                    applicable law, Shrewdmind Private Ltd. shall not be liable
                    for any indirect, incidental, special, consequential, or
                    punitive damages arising out of or related to the use of our
                    website or services. Product Limitations: We make no
                    warranties, express or implied, regarding the products sold
                    on our platform, except for those explicitly stated. The
                    customer assumes all risk associated with the use of the
                    products purchased.
                  </li>
                  <li>
                    Sustainability Initiatives At Shrewdmind, we are committed
                    to reducing our environmental impact. We strive to offer
                    eco-friendly products, minimize waste, and adopt sustainable
                    practices in our operations. From recyclable packaging to
                    supporting local farmers, we take every step to make our
                    services more sustainable. Eco-Friendly Packaging: We use
                    recyclable and biodegradable materials for packaging.
                    Support for Local Farmers: We partner with local suppliers
                    to bring you fresh, locally grown produce. Sustainable
                    Products: Look for eco-friendly products in our catalog that
                    help reduce your carbon footprint. Why Choose Shrewdmind?
                    Comprehensive Selection: From groceries to home essentials,
                    we have everything you need. Convenient Shopping: Shop
                    anytime, anywhere with our easy-to-use platform.
                    Customer-Focused Service: We put your needs first, offering
                    flexible delivery options, personalized shopping, and
                    exceptional customer care. At Shrewdmind Private Ltd., we
                    are more than just an eCommerce platform . we are your
                    trusted partner in everyday shopping. Whether you're
                    stocking up for the week or looking for a last-minute meal
                    solution, we offer services that make your life easier, more
                    affordable, and more convenient.
                  </li>
                </ul>
              </p>
              <div className="row g-0 mb-3">
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                  <h5 className="mb-3 row">
                    <i class="fa fa-envelope" aria-hidden="true"></i>
                    <p style={{ marginLeft: "10px" }}>support@shrewdmind.in</p>
                  </h5>
                </div>
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                  <h5 className="mb-3 row">
                    <i class="fa fa-address-book" aria-hidden="true"></i>
                    <p style={{ marginLeft: "10px" }}>
                      +91- 8076877163 , +91- 8059942293
                    </p>
                  </h5>
                </div>
                <div className="col-sm-9 wow zoomIn" data-wow-delay="0.2s">
                  <h5 className="mb-3 row">
                    <i class="fa fa-address-book" aria-hidden="true"></i>
                    <p style={{ marginLeft: "10px" }}>
                      D-298 Street No 11 Harsh Vihar, 110093 New Delhi
                    </p>
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-5" style={{ minHeight: "500px" }}>
              <div className="position-relative h-25">
                <img
                  className="position-absolute w-100 h-100 rounded wow zoomIn"
                  data-wow-delay="0.9s"
                  src="../Assets/images/blog1.jpg"
                  style={{ objectFit: "cover" }}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurService;
