import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { baseUrl } from "../env";
import axios from "axios";

function OtpVarificationModal(props) {
  const { setVisible, visible } = props;
  const [isError, setIsError] = useState(false);
  let [timer, setTimer] = useState(60);
  const [timerId, setTimerId] = useState();

  const [OtpData, setOtpData] = useState({
    otp: "",
  });

  useEffect(() => {
    clearInterval(timerId);
    if (visible) {
      timerForResendOtp();
    }
  }, [visible]);

  const timerForResendOtp = () => {
    timer = 60;
    setTimer(timer);
    let tid = setInterval(() => {
      if (timer === 0) {
        clearInterval(tid);
      } else {
        timer -= 1;
        setTimer(timer);
      }
    }, 1000);
    setTimerId(tid);
  };

  let emailData = localStorage.getItem("data");
  const resendOTP = (e) => {
    console.log(emailData);
    e.preventDefault();
    let data = { username: emailData };

    let config = {
      method: "POST",
      url: baseUrl + "auth/login",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        localStorage.setItem("otptoken", response.data.data.token);
        timerForResendOtp();
      })
      .catch((error) => {
        console.log("Errors:-", error);
      });
  };

  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setOtpData({ ...OtpData, [name]: value });
  };
  let token = localStorage.getItem("otptoken");

  const handleSubmit = (e) => {
    // e.preventDefault();
    let data = {
      otp: OtpData.otp,
      token: token,
    };

    let config = {
      method: "POST",
      url: baseUrl + "customer/otpverification",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };
    setIsError(false);
    axios
      .request(config)
      .then((response) => {
        handleClose();
        localStorage.setItem("token", response.data.data.token);
        const event = new Event("login");
        event.key = "login";
        event.value = "true";
        document.dispatchEvent(event);
      })
      .catch((error) => {
        console.log("Errors:-", error);
        setIsError(true);
      });
  };
  const handleClose = () => {
    setVisible(false);
  };
  return (
    <div>
      <Modal show={visible}>
        <Modal.Header>
          <Modal.Title>OTP Varification Form</Modal.Title>
        </Modal.Header>
        <Form>
          <Modal.Body>
            <Form.Group controlId="formName">
              <Form.Control
                type="number"
                placeholder="Enter Otp Code"
                value={OtpData.otp}
                name="otp"
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            {isError && <p className="text-danger">Please enter valid otp?</p>}
          </Modal.Body>
          {timer ? (
            <span className="pointer text-primary">
              Otp not received ? {timer}
            </span> 
          ) : (
            <span onClick={resendOTP} className="pointer text-primary">
              Resend
            </span>
          )}
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSubmit} type="button">
              Varify
            </Button>
          </Modal.Footer>
          
        </Form>
      </Modal>
    </div>
  );
}

export default OtpVarificationModal;
