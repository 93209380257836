const { default: Swal } = require("sweetalert2");

exports.errorMessage=(message,title='Error !')=>{
    Swal.fire({
            title,
            text: message,
            icon: "error",
            timer: 3000,
            showConfirmButton: false,
          });
}
exports.successMessage=(message,title='Success !')=>{
    Swal.fire({
            title,
            text: message,
            icon: "success",
            timer: 3000,
            showConfirmButton: false,
          });
}

exports.successWarning=(message,title='Warning !')=>{
    Swal.fire({
            title,
            text: message,
            icon: "warning",
            timer: 3000,
            showConfirmButton: false,
          });
}