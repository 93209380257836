import axios from "axios";
import { baseUrl } from "../env";
 
export const getApi = async (id) => {
    return await axios.get(baseUrl + 'catalouge/share/'+id)
}

export const loginUser = async () => {
   return await axios.post(baseUrl + 'auth/login');
}

export const getProductById=async(id)=>{
    return await axios.get(baseUrl + 'product/getbyid/'+id)
}
export const venderDetail = async(id)=>{
    return await axios.get(baseUrl + 'users/vendorinfo/'+id)
}
export const getUserLocation = async(id)=>{
    return await axios.post(baseUrl + 'catalogue/nearme')
}

export const getCart = async(vendorId)=>{
    return await axios.get(baseUrl + 'cart/get/'+vendorId)
}
export const addToCartItem = async(obj)=>{
    return await axios.post(baseUrl + 'cart/create',obj)
}
export const updateCart = async(obj)=>{
    return await axios.post(baseUrl + 'cart/update',obj)
}